import axiosInstance, { addPlainTextHeader } from "@/store/axios.instance";

export default {
    namespaced: true,
    state() {
        return {

        }
    },

    actions: {
        fetchTasksForClientAndProject({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks`, rootGetters["authModule/getUrlConfig"])
                .then(
                    result => {
                        return result?.data
                    }
                )
        },
        fetchTaskBasic({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/basic`, rootGetters["authModule/getUrlConfig"])
                .then(
                    result => {
                        return result?.data
                    }
                )
        },
        deleteTask({ rootGetters }, data) {
            return axiosInstance.delete(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.taskId}`, rootGetters["authModule/getUrlConfig"])
                .then(
                    result => {
                        return result?.data
                    }
                )
        },
        createTask({ rootGetters }, taskCreateDTO) {
            return axiosInstance.post(`/clients/${taskCreateDTO.clientId}/projects/${taskCreateDTO.projectId}/tasks`, taskCreateDTO, rootGetters["authModule/getUrlConfig"])
        },
        getFunctionalIdSuggestions({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.seed}/gen-functional-id`, rootGetters["authModule/getUrlConfig"])
                .then(
                    result => {
                        return result?.data
                    }
                )
        },
        fetchTaskName({ rootGetters }, data) {
            return axiosInstance.get(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/task-name`, rootGetters["authModule/getUrlConfig"])
                .then((result) => result?.data)
        },
        updateTaskName({ rootGetters }, data) {
            return axiosInstance.put(`/clients/${data.clientId}/projects/${data.projectId}/tasks/${data.functionalId}/task-name`, data.taskName, addPlainTextHeader(rootGetters["authModule/getUrlConfig"]))
        },

    }
}